import React, { useContext, useEffect, useState } from "react";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import dropdown_arrow_blue from "../../assets/images/megaoffer/dropdown_arrow_blue.svg";

const OngoingFreights = ({ frights }) => {
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        {/* <div className="mb-4 d-flex justify-content-between freights-search-select-main ">
          <input
            className="form-control freights-search-select "
            placeholder="Search..."
            type="search"
            aria-label="default input example"
          />

          <select
            className="form-select freights-search-select "
            aria-label="Default select example"
          >
            <option selected>My Vehicles</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div> */}
        {/* <div className="cards">
          <div className="row">
            {frights.map((fright, index) => {
              return (
                <div
                  className="col-lg-6 col-xl-4    mb-3 "
                  key={"fright" + index}
                >
                  <div className="card mb-2">
                    <div className="p-1">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between align-items-center">
                          <img
                            src={
                              ApiConfig.BASE_URL_FOR_IMAGES +
                                fright.trip_image || ic_trucks
                            }
                            alt=""
                            className="card-rsponsive-img vehicle-request-img"
                          />
                          <h5 className="my-free ms-3">
                            {fright.trip_reference_no}
                          </h5>
                        </div>

                        <div className="ongoing-dropdown dropdown ">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <Link
                                to={"/GPS_Shipper/" + fright.trip_id}
                                state={fright}
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  View In Map
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/ViewDetails/" + fright.trip_id}
                                state={fright}
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  View Details
                                </button>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/SelectReportTypes"
                                className="text-decoration-none"
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  Reports
                                </button>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row ms-5 me-2 responsive-card-body">
                        <div className="text-start">
                          <dd className="card-label">{`${fright.trip_start_city} ${fright.trip_start_country}-${fright.trip_end_city} ${fright.trip_end_country}`}</dd>
                          <dt className="small-card-data"></dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd className="card-label">Offered By</dd>
                          <dt className="offered-by text-decoration-underline">
                            {fright.offered_by} ...
                          </dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd className="card-label">Cargo Type</dd>
                          <dt className="small-card-data">
                            {fright.cargo_type}
                          </dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd className="card-label">Quantity</dd>
                          <dt className="small-card-data">
                            {fright.quantity} {fright.unit}.
                          </dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd className="card-label">Started On</dd>
                          <dt className="small-card-data">
                            {fright.trip_start_date}
                          </dt>
                        </div>
                        <div className="d-flex justify-content-between rsponsive-card-data">
                          <dd className="card-label">Est. Start Dt.</dd>
                          <dt className="small-card-data">
                            {fright.trip_start_date}
                          </dt>
                        </div>

                        <div className="btn freights-card-status">
                          <Link to="#">
                            <button type="button" className="">
                              {fright.trip_status}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <React.Fragment>
        <div style={{ margin: "20px" }}>
          <div className="  col-md-6 ">
            <div className="">
              <div
                className=""
                style={{
                  width: "1160px",
                  overflow: "auto",
                  height: "83vh",
                }}
              >
                {/* <ProgressBar progress={progress} /> */}
                {loading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  <table className="dataList">
                    <thead>
                      <tr>
                        <th>{/* <label>Start City</label> */}</th>
                        <th>
                          <label>Start City</label>
                        </th>
                        <th>
                          <label>Offered By</label>
                        </th>
                        <th>
                          <label>Cargo Type</label>
                        </th>
                        <th>
                          <label>Quantity</label>
                        </th>
                        <th>
                          <label>Delivery Dt.</label>
                        </th>
                        {/* <th>
                          <label>Started On</label>
                        </th> */}
                        <th>
                          <label>Status</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {console.log("fri 1" + JSON.stringify(frights))}
                      {frights
                      .filter(e => e.trip_vehicle_status === 'trip_partially_initiated' 
                        || e.trip_vehicle_status==='trip_initiated')
                      .map((freight, index) => {
                        return (
                          <tr
                            className="Rowbgcolor p-2"
                            key={"freight" + index}
                          >
                            <td>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                  <img
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      freight.trip_image
                                    }
                                    alt=""
                                    className="card-rsponsive-img"
                                  />
                                  <h5 className="my-free ms-3">
                                    {freight.trip_reference_no}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              {`${freight.trip_start_city} ${freight.trip_start_country}-${freight.trip_end_city} ${freight.trip_end_country}`}
                            </td>
                            <td
                              className="offered-by text-decoration-underline"
                              id="trip_start_date"
                            >
                              {freight.offered_by} ...
                            </td>
                            <td id="trip_status">{freight.cargo_type}</td>
                            <td id="trip_status">
                              {" "}
                              {freight.quantity} {freight.unit}.
                            </td>
                            <td id="trip_status"> {freight.trip_delivery_date}</td>
                            {/* <td id="trip_status"> {freight.trip_start_date}</td> */}
                            <td
                              id="trip_status"
                              className="freights-card-status"
                            >
                              {" "}
                              {freight.trip_vehicle_status}
                            </td>

                            <td id="">
                              <div className="ongoing-dropdown dropdown ">
                                <a
                                  href=""
                                  className=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="true"
                                >
                                  <img className="" src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  {/* <li>
                                    <Link
                                      to={"/GPS_Shipper/" + freight.trip_id}
                                      state={frights}
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View In Map
                                      </button>
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Link
                                      to={"/ViewDetailsFreight"}
                                      state={freight}
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/SelectReportTypes"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item "
                                        type="button"
                                      >
                                        Reports
                                      </button>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default OngoingFreights;
