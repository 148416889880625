const BASE_URL = "http://abhay-logistics.redbytes.in/api/admin/";
const CLIENT_BASE_URL = "https://delivery-api.abaylogistics.com/api/admin/";
const ApiConfig = {
  // BASE_URL,
  CLIENT_BASE_URL,
  BASE_URL_FOR_IMAGES: "https://delivery-api.abaylogistics.com/",
  LOGIN: CLIENT_BASE_URL + "login",
  ADMIN_LOGOUT_API: CLIENT_BASE_URL + "logout",
  ADD_VEHICLE: CLIENT_BASE_URL + "vehicle/add",
  TRANSPORTER_LIST: CLIENT_BASE_URL + "transporter/list",
  DELIVERY_LIST: CLIENT_BASE_URL + "delivery/list",
  SHIPPER_LIST: CLIENT_BASE_URL + "shipper/list",
  SHIPPER_DETAILS: CLIENT_BASE_URL + "shipper/details",
  COMPANY_TYPE_DROPDOWN: CLIENT_BASE_URL + "dropdown/companytype",
  // ADD_TRANSPORTER: CLIENT_BASE_URL + "transporter/add",
  ADD_TRANSPORTER: "https://delivery-api.abaylogistics.com/api/transporter/tpregister",
  ADD_DELIVERY: "https://delivery-api.abaylogistics.com/api/delivery/tpregister",
  ACCEPT_DELIVERY: "https://delivery-api.abaylogistics.com/api/delivery/tpregister/accept",
  //PENDING_SHIPPER: "https://delivery-api.abaylogistics.com/api/delivery/tpregister/pending",
  PENDING_SHIPPER: CLIENT_BASE_URL + "shipper/pending",
  EDIT_REGISTERED_DELIVERY: "https://delivery-api.abaylogistics.com/api/delivery/tpregister/editdelivery",
  Driver_PENDING_LIST: CLIENT_BASE_URL + "driver/tplist",
  Vehicle_PENDING_LIST: CLIENT_BASE_URL + "vehicleapproval/editlist",
  VEHICLE_APPROVE: CLIENT_BASE_URL + "vehicleapproval/editapprove",
  TRANSPORTER_DETAILS: CLIENT_BASE_URL + "transporter/details",
  DELIVERY_DETAILS: CLIENT_BASE_URL + "delivery/details",
  CURRENCY_DROPDOWN: CLIENT_BASE_URL + "dropdown/currency",
  REJECTED_DIRECT_ORDER_VEHICLE_REQUESTS: CLIENT_BASE_URL + "directorders/transporterrejecteddirectorder",
  ADMIN_REJECTED_DIRECT_ORDER_VEHICLE_REQUESTS: CLIENT_BASE_URL + "directorders/adminrejecteddirectorder",
  REASSIGN: CLIENT_BASE_URL + "directorders/reassign",
  ADMIN_REASSIGN: CLIENT_BASE_URL + "directorders/adminreassign",
  ONLINE_VEHICLE_OFFER_LIST: CLIENT_BASE_URL + "onlineorders/vehicleoffers",
  ONLINE_ORDER_CONFIRMATION_LIST: CLIENT_BASE_URL + "onlineorders/orderconfirmation",
  NOTIFICATION: CLIENT_BASE_URL + "notification",
  ADD_SHIPPER_DROPDOWN: CLIENT_BASE_URL + "dropdown",
  ADD_SHIPPER: CLIENT_BASE_URL + "shipper/add",
  VEHICLE_LIST: CLIENT_BASE_URL + "vehicle/list",
  VEHICLE_DETAILS: CLIENT_BASE_URL + "vehicle/details",
  DRIVER_LIST: CLIENT_BASE_URL + "driver/list",
  ADD_DRIVER: CLIENT_BASE_URL + "driver/add",
  VEHICLE_DROPDOWNLIST: CLIENT_BASE_URL + "vehicle/dropdownlist",
  ONGOING_FRIGHTS: CLIENT_BASE_URL + "freights/ongoing",
  UPCOMMING_FRIGHTS: CLIENT_BASE_URL + "freights/upcoming",
  COMPLETED_FRIGHTS: CLIENT_BASE_URL + "freights/completed",
  VEHICLE_REQUEST: CLIENT_BASE_URL + "vehiclerequest/list",
  APPROOVE_VEHICLE_REQUEST: CLIENT_BASE_URL + "vehiclerequest/approve",
  REJECT_VEHICLE_REQUEST: CLIENT_BASE_URL + "vehiclerequest/reject",
  MYREQUEST_OFFER_VEHICLE: CLIENT_BASE_URL + "myrequests/offervehicle",
  DRIRECT_SHIPPER_STATUS: CLIENT_BASE_URL + "directorders/shipperstatuslisting",
  ONLINE_SHIPPER_STATUS: CLIENT_BASE_URL + "onlineorders/shipperstatuslisting",
  DRIRECT_TRANSPORTER_STATUS:
    CLIENT_BASE_URL + "directorders/transporterstatuslisting",
  DRIRECT_TRANSPORTER_SEND_OFFER_STATUS:
    CLIENT_BASE_URL + "directorders/transportersendofferstatuslisting",
  ONLINE_TRANSPORTER_STATUS:
    CLIENT_BASE_URL + "onlineorders/transporterstatuslisting",
  AUCTION_DETAILS: CLIENT_BASE_URL + "onlineorders/auctiondetails", 
  DIRECT_ORDER_CONFIRMATION_LIST: CLIENT_BASE_URL + "directorders/orderconfirmation",
  ORDER_CONFIRMATION_DETAILS:
    CLIENT_BASE_URL + "directorders/vehicledetails",
  DIRECT_ORDERCONFIRMATION: CLIENT_BASE_URL + "directorders/matchmadelisting",
  ONLINE_ORDERCONFIRMATION: CLIENT_BASE_URL + "onlineorders/matchmadelisting",
  DIRECT_ORDER_VIEW_RESPONSE: CLIENT_BASE_URL + "directorders/viewresponse",
  DIRECT_ORDER_VIEW_VEHICLE_RESPONSE: CLIENT_BASE_URL + "directorders/viewvehiclelist",
  DIRECT_ORDER_SEND_OFFER: CLIENT_BASE_URL + "directorders/sendoffer",
  DIRECT_ORDER_OFFERED_VEHICLE: CLIENT_BASE_URL + "directorders/vehicleoffered",
  MATCH_MAKING_TRANSPORTERLIST:
    CLIENT_BASE_URL + "vehiclecargo/transporterandcargolist",
  MATCH_MAKING_VEHICLES_BYTRANSPORT:
    CLIENT_BASE_URL + "vehiclecargo/vehiclelist",
  DIRECT_ORDER_MYREQUEST: CLIENT_BASE_URL + "myrequests",
  VEHICLE_CARGO_MATCHMAKING: CLIENT_BASE_URL + "vehiclecargo/matching",
  VEHICLE_CARGO_CANCEL_VEHICLE: CLIENT_BASE_URL + "vehiclecargo/cancelvehicle",
  VEHICLE_CARGO_CANCEL_ALL_VEHICLES: CLIENT_BASE_URL + "vehiclecargo/cancelallvehicles",
  
  DROPDOWN_CARGOTYPES: CLIENT_BASE_URL + "dropdown/cargotypes",
  ORDER_CONFIRMATION_TRANSPORTERLIST:
    CLIENT_BASE_URL + "allrequests/transporterlist",
  SEND_ORDER_CONFIRMATION_DIRECT_ORDER: CLIENT_BASE_URL + "allrequests/confirm",
  ONLINE_AUCTIONS_ALLREQUEST: CLIENT_BASE_URL + "onlineorders",
  ONLINE_ORDERES_DETAILS: CLIENT_BASE_URL + "onlineorders/details",
  ONLINE_ORDERS_SEND_OFFER: CLIENT_BASE_URL + "onlineorders/sendoffer",
  DRIVER_DETAILS: CLIENT_BASE_URL + "driver/details",
  TRANSPORTER_VIEW_CONTRACT: CLIENT_BASE_URL + "transporter/viewcontract",
  BLOCK_TRANSPORTER: CLIENT_BASE_URL + "transporter/block",
  UNBLOCK_TRANSPORTER: CLIENT_BASE_URL + "transporter/unblock",
  BLOCK_SHIPPER: CLIENT_BASE_URL + "shipper/block",
  UNBLOCK_SHIPPER: CLIENT_BASE_URL + "shipper/unblock",
  BLOCK_VEHICLE: CLIENT_BASE_URL + "vehicle/block",
  UNBLOCK_VEHICLE: CLIENT_BASE_URL + "vehicle/unblock",
  BLOCK_DRIVER: CLIENT_BASE_URL + "driver/block",
  UNBLOCK_DRIVER: CLIENT_BASE_URL + "driver/unblock",
  FRIGHT_DETAILS: CLIENT_BASE_URL + "freights/details",
  CHANGE_DRIVER: CLIENT_BASE_URL + "driver/changedriver_vehicle",
  VIEW_CONTRACT_SHIPEER: CLIENT_BASE_URL + "shipper/viewcontract",
  LIVE_TRACKING: CLIENT_BASE_URL + "livetrack",
  LIVE_TRACKING_VEHICLE_LIST: CLIENT_BASE_URL + "livetrack/vehiclelist",
  EDIT_TRANSPORTER: CLIENT_BASE_URL + "transporter/edit",
  EDIT_DELIVERY: CLIENT_BASE_URL + "delivery/edit",
  EDIT_SHIPPER: CLIENT_BASE_URL + "shipper/edit",
  EDIT_VEHICLE: CLIENT_BASE_URL + "vehicle/edit",
  EDIT_DRIVER: CLIENT_BASE_URL + "driver/edit",
  ADMIN_DROPDOWN: CLIENT_BASE_URL + "dropdown",
  DRIVERS_BY_TRSNSPORTER: CLIENT_BASE_URL + "transporter/vehicledriver",
  DASHBOARD: CLIENT_BASE_URL + "dashboard",
  TERMS_AND_CONDITION: CLIENT_BASE_URL + "termsandconditions",
  SHIPPER_PENDING_LIST: CLIENT_BASE_URL + "registeredusers/shippers",
  TRANSPORTER_PENDING_LIST: CLIENT_BASE_URL + "registeredusers/transporters",
  DELIVERY_PENDING_LIST: CLIENT_BASE_URL + "registeredusers/delivery",
  // APPROVE_USER: CLIENT_BASE_URL + "registeredusers/approve",
  APPROVE_USER:
    "https://delivery-api.abaylogistics.com/api/admin/registeredusers/approve",
  REJECT_USER: CLIENT_BASE_URL + "registeredusers/reject",
  ACCEPT_USER: CLIENT_BASE_URL + "registeredusers/accept",
  REJECT_VEHICLE: CLIENT_BASE_URL + "vehicle/reject",
  REJECT_DRIVER: CLIENT_BASE_URL + "driver/reject",
  ADD_APPROVE_DRIVER: CLIENT_BASE_URL + "driverapproval/approve",
  DELETE_DRIVER: CLIENT_BASE_URL + "driver/delete",
  DELETE_VEHICLE: CLIENT_BASE_URL + "vehicle/delete",
  DELETE_SHIPPER: CLIENT_BASE_URL + "shipper/delete",
  DELETE_TRANSPORTER: CLIENT_BASE_URL + "transporter/delete",
  PENDING_DELIVERY: CLIENT_BASE_URL + "delivery/pending",
  REPORT_TYPES: CLIENT_BASE_URL + "report/types",
  REPORT_ADD: CLIENT_BASE_URL + "report/add",
  RATE_CHART_LIST: CLIENT_BASE_URL + "tariffchart",
  RATE_CHART_EDIT: CLIENT_BASE_URL + "tariffchart/edit",
  TARRIF_EXPORT_LIST: CLIENT_BASE_URL + "tariffexport",
  TARRIF_EXPORT_EDIT: CLIENT_BASE_URL + "tariffexport/edit",
  TARRIF_IMPORT_LIST: CLIENT_BASE_URL + "tariffimport",
  TARRIF_IMPORT_EDIT: CLIENT_BASE_URL + "tariffimport/edit",
  CHANGE_PASSWORD: CLIENT_BASE_URL + "changepassword",
  DELIVERY_REJECT_LIST:
    CLIENT_BASE_URL + "registeredusers/deliveryrejected",
  TRANSPORTER_REJECT_LIST:
    CLIENT_BASE_URL + "registeredusers/transporterrejected",
  SHIPPER_REJECT_LIST: CLIENT_BASE_URL + "registeredusers/shipperrejected",
  PROFILE: CLIENT_BASE_URL + "dashboard/profile",
  DIRECTORDER_VEHICLELIST: CLIENT_BASE_URL + "directorders/vehiclelist",
  DELETEREJECTED_TRANSPORTER: CLIENT_BASE_URL + "transporter/delete",
  DELETEREJECTED_DELIVERY: CLIENT_BASE_URL + "delivery/delete",
  DELETEREJECTED_SHIPPER: CLIENT_BASE_URL + "shipper/delete",
  // MATCHMAKING_TRANSPORTER_VEHICLE_LIST:
  //   CLIENT_BASE_URL + "vehiclecargo/transportervehiclelist",
  MATCHMAKING_TRANSPORTER_VEHICLE_LIST:
    CLIENT_BASE_URL + "vehiclecargo/transportervehiclelistnew",
  UPDATED_TRANSPORTER_LIST:
    CLIENT_BASE_URL + "registeredusers/updatedtransporters",
  UPDATED_DELIVERY_LIST:
    CLIENT_BASE_URL + "registeredusers/updateddeliveries",
  UPDATED_SHIPPER_LIST: CLIENT_BASE_URL + "registeredusers/updatedshippers",
  TRANSPORTERLIST_FOR_RESSIGN: CLIENT_BASE_URL + "transporter/listforreassign",
  REJECT_EDITED_VEHICLE: CLIENT_BASE_URL + "vehicle/reject",
  VEHICLE_ADD_APPROVAL_LIST: CLIENT_BASE_URL + "vehicleapproval/newlist",
  VEHICLE_ADD_APPROVAL: CLIENT_BASE_URL + "vehicleapproval/approve",
  DRIVER_ADD_APPROVAL_LIST: CLIENT_BASE_URL + "driverapproval/newlist",
  DRIVER_ADD_APPROVAL: CLIENT_BASE_URL + "driverapproval/approve",
  DRIVER_Edit_APPROVAL_LIST: CLIENT_BASE_URL + "driverapproval/editlist",
  DRIVER_Edit_APPROVAL: CLIENT_BASE_URL + "driverapproval/editapprove",
  DIRECT_ORDER_CANCEL_REQUEST: CLIENT_BASE_URL + "directorders/cancelrequest",
  REGISTERED_VEHICLE_LIST:
    "https://delivery-api.abaylogistics.com/api/transporter/tpregister/vehiclelist",
  REGISTERED_DRIVER_LIST:
    "https://delivery-api.abaylogistics.com/api/transporter/tpregister/driverlist",
  REGISTER_ADD_VEHICLE:
    "https://delivery-api.abaylogistics.com/api/transporter/tpregister/vehicleadd",
  REGISTER_ADD_DRIVER:
    "https://delivery-api.abaylogistics.com/api/transporter/tpregister/driveradd",
  SUBMIT: "https://delivery-api.abaylogistics.com/api/transporter/tpregister/submit",

  /* Transporter API url Start*/

  /* Transporter API url End*/
};
export default ApiConfig;
