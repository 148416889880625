import React, { useState, useContext, useEffect } from "react";
import view from "../../assets/images/ic_view.svg";
import { Link } from "react-router-dom";
import Loader from "../../sharedComponent/Loader";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
const DirectOrderConfirmationHistory = () => {
  const { customerData, mainClass } = useContext(AppContext);
  const [orderConfirmLists, setOrderConfirmLists] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOrderConfirmLists();

    // return () => {
    //   setOrderConfirmLists([]);
    // };
  }, []);

  const getOrderConfirmLists = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DIRECT_ORDER_CONFIRMATION_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        setOrderConfirmLists(res.json.load_list);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  return (
    <React.Fragment>
      <div className={`vehicle-list ${mainClass}`} id="cxMain">
        <div style={{ width: "100%", overflow: "auto" }}>
          {loading ? (
            <Loader />
          ) : (
            <table
              className="dataList vehicle-offer-direct-order-table"
              id="example"
              style={{ minWidth: "1050px" }}
            >
              <thead>
                <tr>
                  <th>
                    <label htmlFor="">S.No</label>
                  </th>
                  <th>
                    <label htmlFor="">Reference Number</label>
                  </th>
                  <th>
                    <label htmlFor="">Operation Number</label>
                  </th>
                  <th>{/* <label htmlFor="">Transporter Name</label> */}</th>

                  <th>
                    <label htmlFor="">Shipper Name</label>
                  </th>
                  <th>
                    <label htmlFor="">Loading Place</label>
                  </th>
                  <th>
                    <label htmlFor="">Expected Loading Time</label>
                  </th>
                  <th>
                    <label htmlFor="">Received On</label>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orderConfirmLists &&
                  orderConfirmLists.length &&
                  orderConfirmLists.map((orderList, index) => {
                    return (
                      <tr className="t-row mb-3">
                        <td>{index + 1}</td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.trip_reference_no}{" "}
                        </td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.trip_operation_no}
                        </td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.trip_name}
                        </td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.shipper}
                        </td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.loading_place}
                        </td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.trip_delivery_date}
                        </td>
                        <td id={orderList.trip_id + "_" + index}>
                          {orderList.received_on}
                        </td>
                        <td>
                          <Link
                            to={
                              "/DirectOrderConfirmationDetails"
                            }
                            state={orderList}
                          >
                            <img src={view} alt="" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DirectOrderConfirmationHistory;
