import { AppContext } from "./AppContext";

import React, { useState } from "react";

const AppState = (props) => {
  const [sidebar, setSidebar] = useState(true);
  const [transporterList, setTransporterList] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [mainClass, setMainClass] = useState("cx-active-sidebar");
  const [mainClassB, setMainClassB] = useState("cx-active-sidebarB");
  const [companyTypes, setCompanyTyes] = useState([]);
  let shipperData = JSON.parse(sessionStorage.getItem("shipperData"));
  const [previousPageData, setPreviousPageData] = useState("");
  const [shipperDetails, setShipperDetails] = useState({
    ...shipperData,
  });
  const [dashboardData, setDashboardData] = useState({});
  const [orderType, setOrderType] = useState("");
  const [ApiCaaling, setApiCalling] = useState(false);
  let transporterData = JSON.parse(sessionStorage.getItem("transporterData"));
  const [state, setState] = useState({
    ...transporterData,
  });
  const [customerData, setCustomerData] = useState({
    customer_id: localStorage.getItem("customer_id")
      ? localStorage.getItem("customer_id")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
    api_key: localStorage.getItem("api_key")
      ? localStorage.getItem("api_key")
      : null,
  });
  const [customerDetails, setCustomerDetails] = useState({
    name: localStorage.getItem("name") ? localStorage.getItem("name") : null,
    email: localStorage.getItem("email") ? localStorage.getItem("email") : null,
  });
  let [auctionDetails, setAuctionDetails] = useState({
    auction_name: "",
    auction_type: "",
    start_date: "",
    expiry_date: "",
  });

  const [profileDetails, setProfileDetails] = useState({
    basic_info: {},
    contact_person: {},
    address: {},
    documents: {},
    profile: {}
  });
  const tp_id = localStorage.getItem("tp_id")
    ? localStorage.getItem("tp_id")
    : null;
  const uploadContract = localStorage.getItem("uploadContract")
    ? localStorage.getItem("uploadContract")
    : null;
  const currentId = localStorage.getItem("currentId")
    ? localStorage.getItem("currentId")
    : null;
  return (
    <AppContext.Provider
      value={{
        sidebar,
        vehicleTypes,
        state,
        setState,
        setVehicleTypes,
        ApiCaaling,
        setApiCalling,
        setSidebar,
        mainClass,
        setMainClass,
        mainClassB,
        setMainClassB,
        profileDetails,
        setProfileDetails,
        customerData,
        setCustomerData,
        transporterList,
        setTransporterList,
        companyTypes,
        setCompanyTyes,
        shipperDetails,
        setShipperDetails,
        customerDetails,
        setCustomerDetails,
        previousPageData,
        setPreviousPageData,
        orderType,
        setOrderType,
        auctionDetails,
        setAuctionDetails,
        dashboardData,
        setDashboardData,
        tp_id,
        uploadContract,
        currentId,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
