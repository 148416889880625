import React, { useContext, useEffect, useState } from "react";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
const TransporterResponse = () => {
  
  const params = useParams();
  const id = params.id;
  const [all, setAll] = useState(false);
  const navigate = useNavigate();
  const request = JSON.parse(localStorage.getItem("request"));
  const [requestID] = useState(request && request.trip_id);
  const [respondedTransporterList, setRespondedTransporterList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [notSelectedIdValues, setNotSelectedIdValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { mainClass, customerData, setApiCalling } = useContext(AppContext);
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
  });
  useEffect(() => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DIRECT_ORDER_VIEW_VEHICLE_RESPONSE,
      JSON.stringify({ ...customerData, load_id: requestID, transporter_id: id })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setData(res.json);
          setRespondedTransporterList(
            res.json.transporters.map((trans) => {
              if (true) {
              return {
                ...trans,
                trip_vehicle_id: trans.trip_vehicle_id,
                checked: false,
              };
            }
            })
          
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    return () => {};
  }, []);

  return (
    <main
      className={`vehicle Transporters-Response-main ${mainClass}`}
      id="cxMain"
    >
      <div className="">
        <div style={{ width: "100%", overflow: "auto", padding: "30px 0" }}>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <table
              className="table-striped dataList"
              style={{ maxWidth: "800px" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Transporter’s Name</th>
                  <th>Vehicle's Plate No</th>
                  <th>Responded On</th>
                  <th>Unit Price</th>
                  <th>Loading Capacity</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {respondedTransporterList.map((transporter, index) => {
                  let trailer_id = transporter.trailer_id ? transporter.trailer_id : 'no-trailer';
                  return (
                    <tr className="Rowbgcolor " key={"transporter" + index}>
                      <td>{index + 1}</td>
                      <td>{transporter.transporter}</td>
                      <td>{transporter.vehicle_number}</td>
                      <td>
                        {transporter.responded_on} {transporter.responded_time}
                      </td>
                      <td>{data.unit_price}</td>
                      <td>{transporter.sum_of_vehicle_capacity}</td>
                      <td>{transporter.trip_vehicle_status}</td>
                      <td>
                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className="ms-5 ps-5"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img className="" src={ic_options} alt="" />
                          </a>
                           <ul className="dropdown-menu ">
                            {/* <li>
                              <button className="dropdown-item" type="button">
                                Edit{" "}
                                <img
                                  src={dropdown_arrow_blue}
                                  className="arrowvehicle"
                                  alt=""
                                />
                              </button>
                            </li> */}
                            <li>
                              
                              <Link
                              
                                to={"/VehicleDriverDetails/" +
                                transporter.truck_id + "/" + trailer_id + "/" +
                                transporter.driver_id}
                                className="text-decoration-none"
                                state={transporter}
                              >
                                <button
                                  className="dropdown-item "
                                  type="button"
                                >
                                  View Details
                                </button>
                              </Link>
                            </li>
                            <li></li>

                            {/* <li>
                              <button className="dropdown-item" type="button">
                                Change Over
                              </button>
                            </li> */}
                            {/* <li>
                              <button className="dropdown-item" type="button">
                                Block
                              </button>
                            </li> */}
                          </ul> 
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          <a href="">
            <button onClick={(e) => {                
                e.preventDefault();
                navigate("/TransporterResponseMain");
              }}
              type="button" className="cancel-btn"
              
            >
              Cancel
            </button>
          </a>
          
        </div>
      </div>
    </main>
  );
};

export default TransporterResponse;
