import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import ShipperApproval from "./ShipperApproval";
import TransporterApproval from "./TransporterApproval";
import VehicleApproval from "./VehicleApproval";
import DriverApproval from "./DriverApproval";
import DeliveryApproval from "./DeliveryApproval";
import VehicleDetailReg from "../Vehicles/VehicleDetailReg";

const ApprovalHeader = () => {
  const { mainClassB } = useContext(AppContext);
  const [searchText, setSearchText] = useState("");
  return (
    <main className={`direct-order-main ${mainClassB}`} id="cxMainB">
      <ul
        className="nav nav-pills mb-1 searchOrder"
        id="pills-tab"
        role="tablist"
      >
        
        {/* <li className="nav-item" role="presentation">
          <Link
            to="/VehiclesDetails"
            className="nav-link tab-link active"
            id="pills-driverdetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-driverdetails"
            type="button"
            role="tab"
            aria-controls="pills-driverdetails"
            aria-selected="true"
          >
            Transporter
          </Link>
        </li> */}
        <li className="nav-item" role="presentation">
          <Link
            to="/DeliveryDetails"
            className="nav-link tab-link active"
            id="pills-deliverydetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-deliverydetails"
            type="button"
            role="tab"
            aria-controls="pills-deliverydetails"
            aria-selected="false"
          >
            Delivery
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/DriverDetails"
            className="nav-link tab-link"
            id="pills-customerdetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-customerdetails"
            type="button"
            role="tab"
            aria-controls="pills-customerdetails"
            aria-selected="false"
          >
            Shipper
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/DriverDetails"
            className="nav-link tab-link"
            id="pills-vehicleDetails-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-vehicleDetails"
            type="button"
            role="tab"
            aria-controls="pills-vehicleDetails"
            aria-selected="false"
          >
            Vehicle
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            to="/DriverDetails"
            className="nav-link tab-link"
            id="pills-driverApproval-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-driverApproval"
            type="button"
            role="tab"
            aria-controls="pills-driverApproval"
            aria-selected="false"
          >
            Driver
          </Link>
        </li>
      </ul>
      <div
        className="tab-content "
        id="pills-tabContent"
        style={{ marginBottom: "80px", overflow: "auto" }}
      >
        {/* <div
          className="tab-pane fade show active"
          id="pills-driverdetails"
          role="tabpanel"
          aria-labelledby="pills-driverdetails-tab"
        >
          <DeliveryApproval />
        </div> */}
        <div
          className="tab-pane fade show active"
          id="pills-deliverydetails"
          role="tabpanel"
          aria-labelledby="pills-deliverydetails-tab"
        >
          <DeliveryApproval />
        </div>
        <div
          className="tab-pane fade"
          id="pills-customerdetails"
          role="tabpanel"
          aria-labelledby="pills-customerdetails-tab"
        >
          <ShipperApproval />
        </div>
        <div
          className="tab-pane fade"
          id="pills-vehicleDetails"
          role="tabpanel"
          aria-labelledby="pills-vehicleDetails-tab"
        >
          <VehicleApproval />
        </div>
        <div
          className="tab-pane fade"
          id="pills-driverApproval"
          role="tabpanel"
          aria-labelledby="pills-driverApproval-tab"
        >
          <DriverApproval />
        </div>
      </div>
    </main>
  );
};

export default ApprovalHeader;
