import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import "../../assets/css/Transportvehicle.scss";
import ic_add from "../../assets/images/Trasnvehicle/ic_add.svg";
import ic_import from "../../assets/images/Trasnvehicle/ic_excel.svg";
import ic_export from "../../assets/images/Trasnvehicle/ic_export.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import ChangeDriver from "../Transporter/ChangeDriver";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import WhiteTolltip from "../../sharedComponent/WhiteTolltip";
import * as XLSX from "xlsx"; 
const ShippersLists = () => {
  const { mainClass, customerData, setApiCalling, setShipperDetails } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [shipperList, setShipperList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const blockShipper = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_SHIPPER,
      JSON.stringify({ ...customerData, shipper_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getShipperList();
      }
    });
  };
  const unBlockShipper = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_SHIPPER,
      JSON.stringify({ ...customerData, shipper_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getShipperList();
      }
    });
  };
  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Company Name",
        // "Contact Person",
        // "Business Area",
        // "City",
        // "Country",
        "Contact Phone No",
        "Email",
        "Region",
        "Status",
      ],
    ];
    shipperList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.company_name,
        // elt.contact_person,
        // elt.business_area,
        // elt.user_city,
        // elt.user_country,
        elt.user_mobile,
        elt.user_email,
        elt.user_region,
        elt.user_status,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Shippers_list.xlsx");
  };
  useEffect(() => {
    //$(".dataList").DataTable().destroy();
    let data = originalData.filter((data) => {
      if (selected === "All") return data;
      else if (selected === "inactive") return data.user_status === "blocked" || data.user_status === "pending";
      else if (selected === "active") return data.user_status === "active";
    });
    setShipperList(data);
  }, [selected]);
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  useEffect(() => {
    getShipperList();
    return () => {
      setShipperList({});
    };
  }, []);
  const getShipperList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.SHIPPER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setOriginalData(res.json.shipper_list);
          setShipperList(res.json.shipper_list);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteShipper = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_SHIPPER,
      JSON.stringify({ ...customerData, shipper_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getShipperList();
      }
    });
  };
  return (
    <React.Fragment>
      <div className={`vehicle-list ${mainClass}`} id="cxMain">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div>
            <div className="d-flex  ">
              <div className="input-group   justify-content-end ">
                <div className="icon-group   mt-2">
                  <ul className="d-flex list-unstyled   ">
                    <li>
                      <div className="form-outline searchBox  m-0 ">
                        <label htmlFor="select" className="mr-4">
                          Filter
                        </label>
                        <select
                          className="form-select"
                          onChange={(e) => setSelected(e.target.value)}
                        >
                          <option value={"All"}>All</option>
                          <option value={"active"}>Active</option>
                          <option value={"inactive"}>Inactive</option>
                          {/* <option value={"available"}>Available</option> */}
                        </select>
                      </div>
                    </li>
                    {/* <li className="nav-item icons p-2 me-3 " data-tip="Export">
                      <Link to="#">
                        <img
                          src={ic_export}
                          className="add mb-2"
                          alt="send-load-icon"
                        />
                      </Link>
                    </li> */}
                    
                    <li
                      className="nav-item icons p-2 me-3"
                      data-tip="Export to Excel"
                    >
                      <Link to="#" onClick={exportToexcel}>
                        <img
                          // onClick={() => Setshow(!show)}
                          src={ic_import}
                          className="add"
                          alt="send-load-icon"
                        />
                      </Link>
                      {/* <div className="icon-tooltip">Import Via Excel</div> */}
                    </li>
                    <li className="nav-item icons p-2" data-tip="Add shipper">
                      <Link
                        to="/AddShipper"
                        onClick={(e) => {
                          sessionStorage.clear();
                          setShipperDetails({});
                        }}
                      >
                        <img
                          src={ic_add}
                          className="add"
                          alt="send-load-icon"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className=""
              style={{ overflow: "auto", padding: "30px 0", width: "100%" }}
              id="dropdown-ShiperList"
            >
              <table className="dataList" style={{ minWidth: "900px" }}>
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    <th>
                      <label>Company Name</label>
                    </th>
                    <th>
                      <label>Contact Phone no</label>
                    </th>
                    <th>
                      <label>Email</label>
                    </th>
                    <th>
                      <label>Region</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    {/* <th>
                      <label>Status</label>
                    </th>
                    <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {shipperList.map((shipper, index) => {
                    return (
                      <tr className="Rowbgcolor p-2" key={"shipper" + index}>
                        <td>{index + 1}</td>
                        <td id="">{shipper.company_name} </td>
                        <td id="">
                          {shipper.user_mobile}
                        </td>
                        <td id="">
                          {shipper.user_email}
                        </td>
                        <td id="">{shipper.user_region}</td>
                        {/* <td id="">{shipper.user_city}</td>
                        <td id="">{shipper.user_country}</td> */}
                        <td id="">{shipper.user_status}</td>
                        <td>
                          <div className="option-dropdown dropdown p-2">
                            <a
                              href=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={ic_options} alt="" />
                            </a>
                            <ul className="dropdown-menu ">
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() =>
                                    navigate("/EditShipper/" + shipper.user_id)
                                  }
                                >
                                  Edit{" "}
                                  <img
                                    src={dropdown_arrow_blue}
                                    className="arrowvehicle"
                                    alt=""
                                  />
                                </button>
                              </li>
                              <li>
                                <Link
                                  to={"/ShipperDetails/" + shipper.user_id}
                                  className="text-decoration-none"
                                  state={shipper}
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    View Details
                                  </button>
                                </Link>
                              </li>
                              {shipper.contract === "yes" && (
                                <li>
                                  <Link
                                    to={"/ContractShipper/" + shipper.user_id}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      View Contract
                                    </button>
                                  </Link>
                                </li>
                              )}

                              {/* <li>
                                <button className="dropdown-item" type="button">
                                  Call
                                </button>
                              </li> */}
                              {shipper.user_status === "blocked" ? (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      unBlockShipper(shipper.user_id)
                                    }
                                  >
                                    Unblock
                                  </button>
                                </li>
                              ) : (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      blockShipper(shipper.user_id)
                                    }
                                  >
                                    Block
                                  </button>
                                </li>
                              )}
                              <li>
                              <Link
                                to="#"
                                className="text-decoration-none"
                                onClick={() => deleteShipper(shipper.user_id)}
                              >
                                <button className="dropdown-item" type="button">
                                  Delete
                                </button>
                              </Link>
                            </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <ChangeDriver />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ShippersLists;
