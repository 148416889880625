import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import "../../assets/css/Transportvehicle.scss";
import ic_add from "../../assets/images/Trasnvehicle/ic_add.svg";
import ic_import from "../../assets/images/Trasnvehicle/ic_excel.svg";
import ic_export from "../../assets/images/Trasnvehicle/ic_export.svg";
import ChangeDriver from "../Transporter/ChangeDriver";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import * as XLSX from "xlsx";
//Datatable Modules
import { Modal, Button } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import Filters from "../../sharedComponent/Filters";
import WhiteTolltip from "../../sharedComponent/WhiteTolltip";
const TransporterLists = () => {
  const route = useLocation().pathname;
  const navigate = useNavigate();
  const [selected, setSelected] = useState("All");
  const [specifications, setSpecifications] = useState([]);
  const [loading, setLoading] = useState(false);
  let [orgiginalList, setOrginalList] = useState([]);
  const [transporterList, setTransporterList] = useState([]);
  const { customerData, mainClass, setApiCalling, setState } =
    useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tableRef = useRef(null);
  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
  });
  useEffect(() => {
    getTransporterList();
  }, [route]);
  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Company Type",
        "Company Name",
        "Contact Person",
        "Fleet Size",
        "Vehicles Available",
        "City",
        "Country",
        "Contract",
      ],
    ];
    transporterList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.company_type,
        elt.company_name,
        elt.contact_person,
        elt.user_fleet_size,
        elt.vehicles,
        elt.user_city,
        elt.user_country,
        elt.contract,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(
      wb,
      route === "/DeliveryLists"
        ? "Transporter_list.xlsx"
        : "Cargo_delevery_list.xlsx"
    );
  };
  const blockTransporter = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_TRANSPORTER,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getTransporterList();
      }
    });
  };

  useEffect(() => {
    getTransporterList();
  }, []);
  const getTransporterList = () => {
    var uri = ApiConfig.TRANSPORTER_LIST;
    $(".dataList").DataTable().destroy();
    var jsonBody = JSON.stringify({
      ...customerData,
      user_role:
        route === "/CargoDeleveryLists" ? "cargo-delivery" : "transporter",
    });
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, jsonBody)
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setTransporterList(res.json.transporter_list);
          setOrginalList(res.json.transporter_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  const unBlockTransporter = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_TRANSPORTER,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getTransporterList();
      }
    });
  };

  const deleteTransporter = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETEREJECTED_TRANSPORTER,
      JSON.stringify({ ...customerData, transporter_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getTransporterList();
      }
    });
  };
  useEffect(() => {
    $(".dataList").DataTable().destroy();
    if (selected === "All") setTransporterList(orgiginalList);
    else {
      let filteredData = orgiginalList.filter((data) => {
        if (selected === "yes" || selected === "no") {
          return data.contract === selected;
        } else if (selected === "inactive")
          return data.user_status === "blocked";
        else return data.user_status === selected;
      });
      setTransporterList(filteredData);
    }
  }, [selected]);
  useEffect(() => {
    setOrginalList({});
    setTransporterList({});
  }, []);

  return (
    <React.Fragment>
      <div className={`vehicle-list ${mainClass}`} id="cxMain">
        <div className="">
          <div className="d-flex width-100 ">
            <div className="input-group   justify-content-end ">
              <div className="icon-group   ">
                <ul
                  className="d-flex list-unstyled   "
                  style={
                    {
                      // position: "absolute",
                      // zIndex: "900",
                      // right: "200px",
                      // top: "30px",
                    }
                  }
                >
                  <li>
                    <div className="form-outline searchBox  m-0 ">
                      <Filters onSelect={setSelected} />
                    </div>
                  </li>
                  {/* <li className="nav-item icons p-2 me-3 " data-tip="Export">
                    <Link to="#">
                      <img
                        src={ic_export}
                        className="add mb-2"
                        alt="send-load-icon"
                      />
                    </Link>

                  </li> */}
                  <li
                    className="nav-item icons p-2 me-3"
                    data-tip="Export to Excel"
                  >
                    <Link to="#" onClick={exportToexcel}>
                      <img
                        // onClick={() => Setshow(!show)}
                        src={ic_import}
                        className="add"
                        alt="send-load-icon"
                      />
                    </Link>

                    {/* <div className="icon-tooltip">Import Via Excel</div> */}
                  </li>
                  <li className="nav-item icons p-2" data-tip="Add transporter">
                    <Link
                      to="/AddNewTransporter"
                      onClick={() => {
                        sessionStorage.clear();
                        setState({});
                      }}
                    >
                      <img src={ic_add} className="add" alt="send-load-icon" />
                    </Link>
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="" style={{ overflow: "auto", padding: "30px 0" }}>
            {loading ? (
              <>
                <Loader loading={loading} />
              </>
            ) : (
              <table
                className="dataList"
                id="transporter"
                style={{ minWidth: "1050px" }}
                ref={tableRef}
              >
                <thead>
                  <tr>
                    <th>
                      <label>S.No</label>
                    </th>
                    <th>
                      <label>Company Type</label>
                    </th>
                    <th>
                      <label>Company Name</label>
                    </th>
                    <th>
                      <label>Contact Person</label>
                    </th>
                    <th>
                      <label>Fleet Size</label>
                    </th>
                    <th>
                      <label>Vehicles Available</label>
                    </th>
                    <th>
                      <label>City</label>
                    </th>
                    <th>
                      <label>Country</label>
                    </th>
                    <th>
                      <label>Contract</label>
                    </th>
                    <th>
                      <label>Status</label>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="dropdown-transporterList"
                  id="dropdown-transporterList"
                >
                  {transporterList.length > 0 &&
                    transporterList.map((transporter, index) => {
                      return (
                        <tr
                          className="Rowbgcolor p-2"
                          key={"transporter" + index}
                        >
                          <td>{index + 1}</td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.company_type}{" "}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.company_name}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.contact_person}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.user_fleet_size}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.vehicles}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.user_city}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.user_country}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.contract}
                          </td>
                          <td id={transporter.user_id + "_" + index}>
                            {transporter.user_status}
                          </td>
                          <td>
                            <div className="option-dropdown dropdown  p-2">
                              <Link
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={ic_options} alt="" />
                              </Link>
                              <ul className="dropdown-menu ">
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      navigate(
                                        "/AddTransporter/" + transporter.user_id
                                      )
                                    }
                                  >
                                    Edit
                                    <img
                                      src={dropdown_arrow_blue}
                                      className="arrowvehicle"
                                      alt=""
                                    />
                                  </button>
                                </li>
                                <li>
                                  <Link
                                    className="text-decoration-none"
                                    to={
                                      "/TransporterDetails/" +
                                      transporter.user_id
                                    }
                                    state={transporter}
                                  >
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      View Details
                                    </button>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setSpecifications(
                                        transporter.vehicle_list
                                      );
                                      handleShow();
                                    }}
                                    className="text-decoration-none"
                                  >
                                    <button
                                      className="dropdown-item "
                                      type="button"
                                    >
                                      View Vehicles
                                    </button>
                                  </Link>
                                </li>
                                {transporter.contract === "yes" && (
                                  <li>
                                    <Link
                                      to={
                                        "/ContractTransporter/" +
                                        transporter.user_id
                                      }
                                      className="text-decoration-none"
                                      state={transporter}
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        View Contract
                                      </button>
                                    </Link>
                                  </li>
                                )}

                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    Call
                                  </button>
                                </li>
                                {transporter.user_status === "blocked" ? (
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        unBlockTransporter(transporter.user_id)
                                      }
                                    >
                                      Unblock
                                    </button>
                                  </li>
                                ) : (
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        blockTransporter(transporter.user_id)
                                      }
                                    >
                                      Block
                                    </button>
                                  </li>
                                )}
                                <li>
                                  <Link
                                    to="#"
                                    className="text-decoration-none"
                                    onClick={() =>
                                      deleteTransporter(transporter.user_id)
                                    }
                                  >
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      Delete
                                    </button>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>

          {/* // <div className="">
          //   <div className="row mt-5">
          //     <div className="text-center mt-4">
          //       <Link to="/AddVehicles">
          //         <img src={vehicle_empty_state} className="ps-4" alt="" />
          //       </Link>
          //     </div>
          //   </div>
          // </div> */}

          <ChangeDriver />
          <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-content-box popup-specification"
          >
            <Modal.Header closeButton></Modal.Header>
            <div>
              <h3 className=" card-heading mt-2">Vehicles </h3>
            </div>
            <div
              className="btn-popup-modal text-center"
              style={{ overflow: "auto" }}
            >
              <table
                className="example table-striped"
                style={{ minWidth: "400px" }}
              >
                <thead>
                  <tr>
                    <td>
                      <label htmlFor="">Trucks</label>
                    </td>
                    <td>
                      <label htmlFor="">Vehicle Type</label>
                    </td>
                    <td>
                      <label htmlFor=""> Plate No</label>
                    </td>
                    <td>
                      <label htmlFor=""> Model No</label>
                    </td>
                    <td>
                      <label htmlFor=""> chassis No</label>
                    </td>
                    <td>
                      <label htmlFor="">Capacity in Quintals</label>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {specifications &&
                    specifications.length &&
                    specifications.map((spec, index) => {
                      return (
                        <tr className="t-row " key={"spec" + index}>
                          <td>
                            <p className="data m-0 ps-2">{index + 1}</p>
                          </td>
                          <td>
                            <p className="data m-0">{spec.vehicle_type}</p>
                          </td>
                          <td>
                            <p className="data m-0">{spec.plate_number}</p>
                          </td>
                          <td>
                            <p className="data m-0">{spec.vehicle_model_no}</p>
                          </td>
                          <td>
                            <p className="data m-0">
                              {spec.vehicle_chassis_no}
                            </p>
                          </td>
                          <td>
                            <p className="data m-0">{spec.vehicle_capacity}</p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Modal>
          {/* <div className="Vehiclelistpage">
          <a href="#" className="R-arow">
            {" "}
            <img className="Larow" src={ic_prev} alt="" />{" "}
            <img src={ic_prev_active} alt="" />{" "}
          </a>
          <a href="#">1</a>
          <a href="#">2 </a>
          <a href="#">3</a>
          <a href="#">4</a>
          <a href="#" className="L-arow">
            <img className="Larow" src={ic_next} alt="" />{" "}
            <img src={ic_next_active} alt="" />{" "}
          </a>
        </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default TransporterLists;
